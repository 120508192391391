import dayjs from "dayjs";
import type { Maybe } from "@vue-storefront/unified-data-model";
import { isFutureDate } from "mkm-avengers";
import { toRefs } from "@vueuse/core";

import advancedFormat from "dayjs/plugin/advancedFormat";
import { useConfig } from "~/constants";
import { Branch } from "mkm-types";

export interface UseBranchesState {
  selectedBranch: Maybe<Branch>;
  selectedBranchId: Maybe<string>;
  allFetchedBranches: Maybe<Branch[] | null>;
  loading: boolean;
  loadingAllBranches: boolean;
  loadingNearestBranch: boolean;
  loadingBranchByID: boolean;
  loadingBranchBySlug: boolean;
  accountBranch: Branch | null;
  branchToSwitchFromId: number | null;
}

const isNewBranch = (branch: Branch) => {
  if (branch) {
    const openingDate = branch["opening_date"];
    return openingDate ? isFutureDate(new Date(openingDate)) : undefined;
  }
};

const getNewBranchOpeningDate = (branch: Branch) => {
  dayjs.extend(advancedFormat);

  const openingDate = dayjs(branch["opening_date"]).format("Do MMMM YYYY");
  const today = dayjs(new Date()).format("Do MMMM YYYY");

  return openingDate === today ? "Today" : openingDate;
};

const opensInTheFuture = (branch: Branch) => {
  dayjs.extend(advancedFormat);

  const tomorrow = dayjs(new Date())
    .add(1, "day")
    .set("hour", 0)
    .set("minute", 0)
    .set("second", 0)
    .set("millisecond", 0);

  return branch["opening_date"] ? dayjs(new Date(branch["opening_date"])) >= tomorrow : undefined;
};

const useBranches = () => {
  const { user } = useUser();
  const { baseUrl, headers } = useConfig();

  const state = useState<UseBranchesState>(`useBranches`, () => ({
    selectedBranch: null,
    selectedBranchId: null,
    allFetchedBranches: null,
    loading: false,
    loadingAllBranches: false,
    loadingNearestBranch: false,
    loadingBranchByID: false,
    loadingBranchBySlug: false,
    accountBranch: null,
    branchToSwitchFromId: null,
  }));

  const fetchBranch = async (): Promise<void> => {
    state.value.loading = true;

    const { data: branchData, error } = await useAsyncData("getBranch", () =>
      $fetch<any>(`${baseUrl}/getBranch`, {
        headers,
        credentials: "include",
      }),
    );

    state.value.selectedBranch = branchData.value ?? state.value.selectedBranch;
    state.value.selectedBranchId = branchData.value?.id ?? state.value.selectedBranchId;

    useHandleError(error.value);

    state.value.loading = false;
  };

  const fetchAllBranches = async (): Promise<Branch[] | null> => {
    state.value.loadingAllBranches = true;
    const { data: allBranchData, error } = await useAsyncData("getAllBranches", () => useSdk().mkmAPI.getBranches({}));

    state.value.allFetchedBranches = allBranchData.value ?? state.value.allFetchedBranches;

    useHandleError(error.value);

    state.value.loadingAllBranches = false;

    return allBranchData.value;
  };

  const fetchBranchById = async (id: string): Promise<Maybe<Branch[]>> => {
    state.value.loadingBranchByID = true;
    const { data: branchData, error } = await useAsyncData("getBranchById", () => useSdk().mkmAPI.getBranches({ id }));

    useHandleError(error.value);

    state.value.loadingBranchByID = true;

    return branchData.value;
  };

  const fetchBranchBySlug = async (slug: string): Promise<Maybe<Branch[]>> => {
    state.value.loadingBranchBySlug = true;
    const { data: branchData, error } = await useAsyncData("getBranchBySlug", () =>
      useSdk().mkmAPI.getBranches({ slug }),
    );

    useHandleError(error.value);

    state.value.loadingBranchBySlug = false;

    return branchData.value;
  };

  const fetchNearestBranch = async (params: {
    lat: number;
    lng: number;
    limit?: number;
    excludeBranchId?: number | number[];
    includeComingSoon?: boolean;
  }) => {
    const { lat, lng, limit, excludeBranchId, includeComingSoon } = params;
    const shouldIncludeComingSoon = includeComingSoon ? 1 : 0;

    state.value.loadingNearestBranch = true;

    const { data: branchData, error } = await useAsyncData("getNearestBranch", () =>
      useSdk().mkmAPI.getBranches({ lat, lng, limit, excludeBranchId, includeComingSoon: shouldIncludeComingSoon }),
    );

    useHandleError(error.value);

    state.value.loadingNearestBranch = false;

    return branchData.value;
  };

  const fetchAccountBranch = async (): Promise<void> => {
    if (!user.value) return;

    const { data: branchData, error } = await useAsyncData("getBranchById", () =>
      useSdk().mkmAPI.getBranches({ id: user.value?.account.branch_id.toString() || "" }),
    );

    useHandleError(error.value);

    state.value.accountBranch = branchData.value ? branchData.value[0] : null;
  };

  const selectBranch = async (branchId: number) => {
    state.value.loading = true;
    const { data: branchData, error } = await useAsyncData("selectBranch", () => useSdk().mkmAPI.setBranch(branchId));

    state.value.selectedBranch = branchData.value ?? state.value.selectedBranch;
    state.value.selectedBranchId = branchData.value?.id ?? state.value.selectedBranchId;

    useHandleError(error.value);

    state.value.loading = false;
  };

  return {
    fetchBranch,
    fetchAllBranches,
    selectBranch,
    fetchBranchById,
    fetchBranchBySlug,
    fetchNearestBranch,
    isNewBranch,
    getNewBranchOpeningDate,
    opensInTheFuture,
    fetchAccountBranch,
    ...toRefs(state.value),
  };
};

export default useBranches;
