/**
 * Check if the given date is in the future
 * @param date The date to check
 * @returns True if the date is in the future, false otherwise
 * @example
 **/

export const isFutureDate = (date: Date) => {
  const today = new Date();
  today.setHours(0, 0, 0, 0);
  return date > today;
};
